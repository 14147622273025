import { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import Masonry from 'react-masonry-css'; // Masonry CSS kütüphanesi
import './App.css'; // Stil dosyasını unutma!

export default function App() {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const particlesRef = useRef(null);

  // Replace this with your actual API key
  const OPENAI_API_KEY = 'sk-50Jn25k1t-CKE0UPW17P3ssirVbD5B2EFOt-jWKReaT3BlbkFJb9sGbuTF0DP5xqbT7Wed0EhsMngsRo8KEr-fA3xgUA';

  const handleQuestionSubmit = async (event) => {
    event.preventDefault();
    if (!question) return;

    setAnswer('Processing your question...');

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',  // Yeni endpoint
        {
          model: 'gpt-4o-mini',  // Kullanmak istediğin model
          messages: [
            { role: 'user', content: question },  // Mesaj formatı: Kullanıcıdan gelen soru
          ],
          max_tokens: 100,  // Dönecek cevabın maksimum token sayısı
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${OPENAI_API_KEY}`,  // API anahtarını ekle
          },
        }
      );

      // API'den dönen cevabı işleme
      const assistantResponse = response.data.choices[0].message.content.trim();
      setAnswer(assistantResponse);
      setChatHistory([...chatHistory, { role: 'user', content: question }, { role: 'assistant', content: assistantResponse }]);
      setQuestion('');
    } catch (error) {
      setAnswer('Error fetching response. Please try again.');
    }
  };

  useEffect(() => {
    const canvas = particlesRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const particles = [];
    for (let i = 0; i < 100; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        size: Math.random() * 2 + 1,
        speedX: Math.random() * 0.5 - 0.25,
        speedY: Math.random() * 0.5 - 0.25,
      });
    }

    function animateParticles() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particles.forEach((particle) => {
        ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
        ctx.fill();

        particle.x += particle.speedX;
        particle.y += particle.speedY;

        if (particle.x < 0 || particle.x > canvas.width) particle.speedX *= -1;
        if (particle.y < 0 || particle.y > canvas.height) particle.speedY *= -1;
      });
      requestAnimationFrame(animateParticles);
    }

    animateParticles();
  }, []);

  // Referans resimlerinin düzenlenmesi için
  const images = [
    { src: "/images/references/referans (1).png", alt: "Referans 1" },
    { src: "/images/references/referans (2).png", alt: "Referans 2" },
    { src: "/images/references/referans (3).png", alt: "Referans 3" },
    { src: "/images/references/referans (4).png", alt: "Referans 4" },
    { src: "/images/references/referans (5).png", alt: "Referans 5" },
    // { src: "/images/references/referans (6).png", alt: "Referans 6" },
    { src: "/images/references/referans (7).png", alt: "Referans 7" },
    { src: "/images/references/referans (8).png", alt: "Referans 8" },
    { src: "/images/references/referans (9).png", alt: "Referans 9" },
    { src: "/images/references/referans (10).png", alt: "Referans 10" },
    { src: "/images/references/referans (11).png", alt: "Referans 11" },
    { src: "/images/references/referans (12).png", alt: "Referans 12" },
    { src: "/images/references/referans (13).png", alt: "Referans 13" },
    { src: "/images/references/referans (14).png", alt: "Referans 14" },
    { src: "/images/references/referans (15).png", alt: "Referans 15" },
    { src: "/images/references/referans (16).png", alt: "Referans 16" },
    { src: "/images/references/referans (17).png", alt: "Referans 17" },  
  ];

  // Masonry layout for responsive grids
  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-blue-900 text-white overflow-hidden">
      <canvas
        ref={particlesRef}
        className="absolute inset-0 pointer-events-none z-0"
        style={{ mixBlendMode: 'screen' }}
      />
      <div className="container mx-auto px-4 py-16 relative z-10">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-5xl font-bold mb-8 text-center"
        >
          Hakkımızda
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-xl mb-12 text-center max-w-3xl mx-auto"
        >
          OPEN AI API Desteğiyle Yapay Zeka Çözümleri Geliştiriyoruz. Amacımız, ileri seviye yapay zeka uygulamaları ve bulut tabanlı çözümler sunarak müşterilerimize en iyi deneyimi sağlamak.
        </motion.p>
        <div className="grid md:grid-cols-2 gap-12 mt-16">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="bg-white bg-opacity-10 p-8 rounded-lg backdrop-filter backdrop-blur-lg"
          >
            <h2 className="text-3xl font-semibold mb-4">Yapay Zeka Çözümleri</h2>
            <p className="text-gray-300">
              Yapay zeka projelerinde en son teknolojileri kullanarak kişisel asistanlar, chatbot'lar, görüntü işleme ve doğal dil işleme projeleri geliştiriyoruz. GPT tabanlı API entegrasyonlarıyla etkileyici çözümler sunuyoruz.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="bg-white bg-opacity-10 p-8 rounded-lg backdrop-filter backdrop-blur-lg"
          >
            <h2 className="text-3xl font-semibold mb-4">Cloud Sunucu ve Uygulama Yönetimi</h2>
            <p className="text-gray-300">
              Sunucuların kurulumundan yönetimine kadar tüm süreci üstleniyoruz. Uygulamalarınızı bulut tabanlı altyapılar üzerinde güvenle çalıştırmanızı sağlıyoruz.
            </p>
          </motion.div>
        </div>

        {/* Referans Çalışmalarımız Bölümü */}
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.8 }}
          className="mt-16 text-center"
        >
          <h2 className="text-3xl font-semibold mb-4">Referans Çalışmalarımız</h2>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {images.map((image, index) => (
              <div key={index} className="p-2">
                <img src={image.src} alt={image.alt} className="w-full rounded-lg" />
              </div>
            ))}
          </Masonry>
        </motion.div>

        {/* AI Demo Section */}
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 1.0 }}
          className="mt-16 text-center"
        >
          <h2 className="text-3xl font-semibold mb-4">AI Asistan Demo</h2>
          <form onSubmit={handleQuestionSubmit} className="mb-8">
            <input
              type="text"
              id="question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Sorunuzu buraya girin..."
              className="p-4 w-full text-gray-900 rounded-lg mb-4"
            />
            <button type="submit" className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg">
              Sor
            </button>
          </form>
          <div className="bg-white bg-opacity-10 p-4 rounded-lg backdrop-filter backdrop-blur-lg">
            <h3 className="text-xl font-semibold mb-2">AI Asistan Cevabı:</h3>
            <p>{answer}</p>
          </div>
        </motion.div>

        {/* Sohbet Geçmişi */}
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 1.2 }}
          className="mt-16 text-center"
        >
          <h3 className="text-2xl font-semibold mb-4">Sohbet Geçmişi:</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {chatHistory.map((entry, index) => (
              <div key={index} className="bg-white bg-opacity-10 p-4 rounded-lg backdrop-filter backdrop-blur-lg">
                <p><strong>{entry.role === 'user' ? 'Siz' : 'AI Asistan'}:</strong> {entry.content}</p>
              </div>
            ))}
          </div>
        </motion.div>

        {/* Footer */}
        <footer className="mt-16 text-center">
          <p>Hazırlayan: Aykut Bozalan</p>
        </footer>
      </div>
    </div>
  );
}
